import each from 'lodash/each';
import groupBy from 'lodash/groupBy';

import { RawTimeRange } from '../types/time';

import * as dateMath from './datemath';
import { isDateTime, DateTime } from './moment_wrapper';

const spans: { [key: string]: { display: string; section?: number } } = {
  s: { display: 'second' },
  m: { display: 'minute' },
  h: { display: 'hour' },
  d: { display: 'day' },
  w: { display: 'week' },
  M: { display: 'month' },
  y: { display: 'year' },
};

const rangeOptions = [
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Yesterday', section: 0 },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Day 2 backward', section: 0 },
  { from: 'now-3d/d', to: 'now-3d/d', display: 'Day 3 backward', section: 0 },
  { from: 'now-4d/d', to: 'now-4d/d', display: 'Day 4 backward', section: 0 },
  { from: 'now-5d/d', to: 'now-5d/d', display: 'Day 5 backward', section: 0 },
  { from: 'now-6d/d', to: 'now-6d/d', display: 'Day 6 backward', section: 0 },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Day 7 backward', section: 0 },
  { from: 'now-2d/d', to: 'now-1d/d', display: 'Last 2 days (avg)', section: 0 },
  { from: 'now-3d/d', to: 'now-1d/d', display: 'Last 3 days (avg)', section: 0 },
  { from: 'now-4d/d', to: 'now-1d/d', display: 'Last 4 days (avg)', section: 0 },
  { from: 'now-5d/d', to: 'now-1d/d', display: 'Last 5 days (avg)', section: 0 },
  { from: 'now-6d/d', to: 'now-1d/d', display: 'Last 6 days (avg)', section: 0 },
  { from: 'now-7d/d', to: 'now-1d/d', display: 'Last 7 days (avg)', section: 0 },

  { from: 'now/d', to: 'now/d', display: 'Today', section: 1 },
  { from: 'now/w', to: 'now/w', display: 'This week', section: 1 },
  { from: 'now/w', to: 'now', display: 'This week so far', section: 1 },
  { from: 'now/M', to: 'now', display: 'This month so far', section: 1 },
  { from: 'now/y', to: 'now', display: 'This year so far', section: 1 },

  { from: 'now+1d/d', to: 'now+1d/d', display: 'Tomorow', section: 2 },
  { from: 'now+2d/d', to: 'now+2d/d', display: 'Day 2 forward', section: 2 },
  { from: 'now+3d/d', to: 'now+3d/d', display: 'Day 3 forward', section: 2 },
  { from: 'now+4d/d', to: 'now+4d/d', display: 'Day 4 forward', section: 2 },
  { from: 'now+5d/d', to: 'now+5d/d', display: 'Day 5 forward', section: 2 },
  { from: 'now+6d/d', to: 'now+6d/d', display: 'Day 6 forward', section: 2 },
  { from: 'now+7d/d', to: 'now+7d/d', display: 'Day 7 forward', section: 2 },
  { from: 'now+1d/d', to: 'now+2d/d', display: 'Next 2 days (avg)', section: 2 },
  { from: 'now+1d/d', to: 'now+3d/d', display: 'Next 3 days (avg)', section: 2 },
  { from: 'now+1d/d', to: 'now+4d/d', display: 'Next 4 days (avg)', section: 2 },
  { from: 'now+1d/d', to: 'now+5d/d', display: 'Next 5 days (avg)', section: 2 },
  { from: 'now+1d/d', to: 'now+6d/d', display: 'Next 6 days (avg)', section: 2 },
  { from: 'now+1d/d', to: 'now+7d/d', display: 'Next 7 days (avg)', section: 2 },
];

const absoluteFormat = 'YYYY-MM-DD HH:mm:ss';

const rangeIndex: any = {};
each(rangeOptions, (frame: any) => {
  rangeIndex[frame.from + ' to ' + frame.to] = frame;
});

export function getRelativeTimesList(timepickerSettings: any, currentDisplay: any) {
  const groups = groupBy(rangeOptions, (option: any) => {
    option.active = option.display === currentDisplay;
    return option.section;
  });

  // _.each(timepickerSettings.time_options, (duration: string) => {
  //   let info = describeTextRange(duration);
  //   if (info.section) {
  //     groups[info.section].push(info);
  //   }
  // });

  return groups;
}

function formatDate(date: DateTime) {
  return date.format(absoluteFormat);
}

// handles expressions like
// 5m
// 5m to now/d
// now/d to now
// now/d
// if no to <expr> then to now is assumed
export function describeTextRange(expr: any) {
  const isLast = expr.indexOf('+') !== 0;
  if (expr.indexOf('now') === -1) {
    expr = (isLast ? 'now-' : 'now') + expr;
  }

  let opt = rangeIndex[expr + ' to now'];
  if (opt) {
    return opt;
  }

  if (isLast) {
    opt = { from: expr, to: 'now' };
  } else {
    opt = { from: 'now', to: expr };
  }

  const parts = /^now([-+])(\d+)(\w)/.exec(expr);
  if (parts) {
    const unit = parts[3];
    const amount = parseInt(parts[2], 10);
    const span = spans[unit];
    if (span) {
      opt.display = isLast ? 'Last ' : 'Next ';
      opt.display += amount + ' ' + span.display;
      opt.section = span.section;
      if (amount > 1) {
        opt.display += 's';
      }
    }
  } else {
    opt.display = opt.from + ' to ' + opt.to;
    opt.invalid = true;
  }

  return opt;
}

/**
 * Use this function to get a properly formatted string representation of a {@link @grafana/data:RawTimeRange | range}.
 *
 * @example
 * ```
 * // Prints "2":
 * console.log(add(1,1));
 * ```
 * @category TimeUtils
 * @param range - a time range (usually specified by the TimePicker)
 * @alpha
 */
export function describeTimeRange(range: RawTimeRange): string {
  const option = rangeIndex[range.from.toString() + ' to ' + range.to.toString()];
  if (option) {
    return option.display;
  }

  if (isDateTime(range.from) && isDateTime(range.to)) {
    return formatDate(range.from) + ' to ' + formatDate(range.to);
  }

  if (isDateTime(range.from)) {
    const toMoment = dateMath.parse(range.to, true);
    return toMoment ? formatDate(range.from) + ' to ' + toMoment.fromNow() : '';
  }

  if (isDateTime(range.to)) {
    const from = dateMath.parse(range.from, false);
    return from ? from.fromNow() + ' to ' + formatDate(range.to) : '';
  }

  if (range.to.toString() === 'now') {
    const res = describeTextRange(range.from);
    return res.display;
  }

  return range.from.toString() + ' to ' + range.to.toString();
}

export const isValidTimeSpan = (value: string) => {
  if (value.indexOf('$') === 0 || value.indexOf('+$') === 0) {
    return true;
  }

  const info = describeTextRange(value);
  return info.invalid !== true;
};

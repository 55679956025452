import _ from 'lodash';
import { coreModule } from 'app/core/core';
import { MetricsPanelCtrl } from 'app/plugins/sdk';
import { AnnotationEvent } from '@grafana/data';
import { dateTime } from '@grafana/data';
import { AnnotationsSrv, SologsalgTools } from './all';
import { ContextSrv } from 'app/core/services/context_srv';

export class EventEditorCtrl {
  panelCtrl: MetricsPanelCtrl;
  event: AnnotationEvent;
  timeRange: { from: number; to: number };
  form: any;
  close: any;
  timeFormated: string;
  toFormated: string;
  siteId: string;
  itemId: string;
  siteName: string;
  itemName: string;
  versionId: string;
  historicalForecastId: string;
  selectedAction: any;
  selectedActionCampaign: any;
  eventActions: any;
  eventActionsCampaign: any;
  selectedActivity: any;
  eventActivities: any;
  selectedAdjustValue: any;
  eventAdjustValues: any;
  selectedAdjustValid: any;
  eventAdjustValid: any;
  eventAdjustValidLocked: any;
  eventAdjustCount: string;
  selectAccept = false;
  selectExport = false;
  selectIgnoreSale = false;
  exportAllowed = false;
  disableAnnotation = true;
  campaignAnnotation = false;

  /** @ngInject */
  constructor(private annotationsSrv: AnnotationsSrv, private contextSrv: ContextSrv) {
    this.event.panelId = this.panelCtrl.panel.id;
    this.event.dashboardId = this.panelCtrl.dashboard.id;

    // Annotations query returns time as Unix timestamp in milliseconds
    this.event.time = tryEpochToMoment(this.event.time);
    if (this.event.isRegion) {
      this.event.timeEnd = tryEpochToMoment(this.event.timeEnd);
    }
    const description: string = this.panelCtrl.panel.description;
    if (description) {
      this.disableAnnotation = !description.toLowerCase().includes('edit activated');
      this.campaignAnnotation = description.toLowerCase().includes('campaign edit activated');
    }
    if (this.disableAnnotation) {
      setTimeout(() => {
        this.close();
      }, 200);
    }
    const timezone = this.panelCtrl.dashboard.getTimezone();
    this.timeFormated = SologsalgTools.formatFromDate(_.cloneDeep(this.event.time), timezone);
    this.toFormated = SologsalgTools.formatToDate(_.cloneDeep(this.event.timeEnd), timezone);
    this.eventActions = SologsalgTools.eventActions;
    this.eventActionsCampaign = SologsalgTools.eventActionsCampaign;
    this.eventActivities = SologsalgTools.eventActivities;
    this.eventAdjustValues = SologsalgTools.eventAdjustValues;
    this.eventAdjustValid = SologsalgTools.eventAdjustValid;
    this.eventAdjustValidLocked = this.eventAdjustValid.slice(0, 1);

    const variables = this.panelCtrl.dashboard.getVariables();
    this.siteId = SologsalgTools.getVaribleByName('Butikk', variables);
    this.itemId = SologsalgTools.getVaribleByName('Vare', variables);
    this.siteName = SologsalgTools.getVariableTextByValue('Butikk', this.siteId, variables);
    this.itemName = SologsalgTools.getVariableTextByValue('Vare', this.itemId, variables);
    this.versionId = SologsalgTools.getVaribleByName('Versjon', variables);
    this.exportAllowed = SologsalgTools.canExport(this.siteName, this.itemName);
    this.historicalForecastId = SologsalgTools.getVaribleByName('Run', variables);

    if (this.event.tags && this.event.tags.length >= 3) {
      const adjustVal = SologsalgTools.findTagAsString('adjustCount', this.event.tags);
      this.eventAdjustCount = adjustVal !== null ? adjustVal.value : null;
      this.selectedAction = this.getSelectorByTag('selectedAction', this.eventActions);
      this.selectedActionCampaign = this.getSelectorByTag('selectedActionCampaign', this.eventActionsCampaign);
      this.selectedActivity = this.getSelectorByTag('selectedActivity', this.eventActivities);
      this.selectedAdjustValue = this.getSelectorByTag('selectedAdjustValue', this.eventAdjustValues);
      this.selectedAdjustValid = this.getSelectorByTag('selectedAdjustValid', this.eventAdjustValid);
      const selectIgnoreSale = SologsalgTools.findTagAsBoolean('ignoreSale', this.event.tags);
      this.selectIgnoreSale = selectIgnoreSale !== null ? selectIgnoreSale.value : false;
    }
  }

  save() {
    if (!this.form.$valid) {
      return;
    }

    const saveModel = _.cloneDeep(this.event);
    saveModel.time = saveModel.time.valueOf();
    saveModel.timeEnd = 0;

    if (saveModel.isRegion) {
      saveModel.timeEnd = this.event.timeEnd.valueOf();

      if (saveModel.timeEnd < saveModel.time) {
        console.log('invalid time');
        return;
      }
    }
    this.insertOrUpdateTags(saveModel);

    if (saveModel.id) {
      this.annotationsSrv
        .updateAnnotationEvent(saveModel)
        .then(() => {
          this.panelCtrl.refresh();
          this.close();
        })
        .catch(() => {
          this.panelCtrl.refresh();
          this.close();
        });
    } else {
      this.annotationsSrv
        .saveAnnotationEvent(saveModel)
        .then(() => {
          this.panelCtrl.refresh();
          this.close();
        })
        .catch(() => {
          this.panelCtrl.refresh();
          this.close();
        });
    }
  }

  delete() {
    return this.annotationsSrv
      .deleteAnnotationEvent(this.event)
      .then(() => {
        this.panelCtrl.refresh();
        this.close();
      })
      .catch(() => {
        this.panelCtrl.refresh();
        this.close();
      });
  }

  private insertOrUpdateTags(annotation: AnnotationEvent) {
    let newTags = [];
    newTags.push(`siteId:${this.siteId}`);
    newTags.push(`itemId:${this.itemId}`);
    newTags.push(`versionId:${this.versionId}`);
    newTags.push(`userEmail:${this.contextSrv.user.email}`);
    newTags.push(`adjustCount:${this.eventAdjustCount}`);
    if (this.selectedAction) {
      newTags.push(`selectedAction:${this.selectedAction.id}`);
    }
    if (this.historicalForecastId) {
      newTags.push(`selectedHistoricalForecastId:${this.historicalForecastId}`);
    }
    if (this.selectedActionCampaign) {
      newTags.push(`selectedActionCampaign:${this.selectedActionCampaign.id}`);
    }
    if (this.selectedActivity) {
      newTags.push(`selectedActivity:${this.selectedActivity.id}`);
    }
    if (this.selectedAdjustValue) {
      newTags.push(`selectedAdjustValue:${this.selectedAdjustValue.id}`);
    }
    if (this.selectedAdjustValid) {
      newTags.push(`selectedAdjustValid:${this.selectedAdjustValid.id}`);
    }
    if (this.selectExport) {
      newTags.push(`selectedExport:true`);
    }
    if (this.selectIgnoreSale) {
      newTags.push(`ignoreSale:true`);
    }
    annotation.tags = newTags;
  }

  private getSelectorByTag(selectorName: string, selections: any[]) {
    const selectorTag = this.event.tags.find(tag => {
      return tag.startsWith(selectorName);
    });
    if (selectorTag) {
      const val = selectorTag.split(':')[1];
      return selections.find(option => option.id === parseInt(val, 10));
    }
  }
}

function tryEpochToMoment(timestamp: any) {
  if (timestamp && _.isNumber(timestamp)) {
    const epoch = Number(timestamp);
    return dateTime(epoch);
  } else {
    return timestamp;
  }
}

export function eventEditor() {
  return {
    restrict: 'E',
    controller: EventEditorCtrl,
    bindToController: true,
    controllerAs: 'ctrl',
    templateUrl: 'public/app/features/annotations/partials/event_editor.html',
    scope: {
      panelCtrl: '=',
      event: '=',
      close: '&',
    },
  };
}

coreModule.directive('eventEditor', eventEditor);

import React, { FC } from 'react';

export const Footer: FC = React.memo(() => {
  return (
    <footer className="footer">
      <div className="text-center">
        <ul>Frivind AS</ul>
      </div>
    </footer>
  );
});

import { dateTime, DateTimeInput, isDateTime, toUtc } from '@grafana/data';

export class SologsalgTools {
  static get eventActions() {
    return [
      { name: 'Comment event, forecast or sale', id: 0 },
      { name: 'Mark activity', id: 1 },
      { name: 'Override forecast (percent)', id: 2 },
      { name: 'Override forecast (quantity)', id: 3 },
      { name: 'Use historical forecast', id: 4 },
    ];
  }

  static get eventActionsCampaign() {
    return [{ name: 'Operational campaign forecast', id: 0 }];
  }

  static get eventActivities() {
    return [
      { name: 'Sales influencing activity - Price', id: 0 },
      { name: 'Sales influencing activity - Exposure', id: 1 },
      { name: 'Sales influencing activity - Price and exposure', id: 2 },
      { name: 'Sales influencing activity - Other', id: 3 },
      { name: 'External influencing event - Sales increasing', id: 4 },
      { name: 'External influencing event - Sales reducing', id: 5 },
    ];
  }

  static get eventAdjustValues() {
    return [
      { name: '-100%', id: 0 },
      { name: '-90%', id: 1 },
      { name: '-80%', id: 2 },
      { name: '-70%', id: 3 },
      { name: '-60%', id: 4 },
      { name: '-50%', id: 5 },
      { name: '-40%', id: 6 },
      { name: '-30%', id: 7 },
      { name: '-20%', id: 8 },
      { name: '-10%', id: 9 },
      { name: '0%', id: 10 },
      { name: '+10%', id: 11 },
      { name: '+20%', id: 12 },
      { name: '+30%', id: 13 },
      { name: '+40%', id: 14 },
      { name: '+50%', id: 15 },
      { name: '+60%', id: 16 },
      { name: '+70%', id: 17 },
      { name: '+80%', id: 18 },
      { name: '+90%', id: 19 },
      { name: '+100%', id: 20 },
      { name: '+150%', id: 21 },
      { name: '+200%', id: 22 },
      { name: '+250%', id: 23 },
      { name: '+300%', id: 23 },
    ];
  }

  static get eventAdjustValid() {
    return [
      { name: 'Lock forecast', id: 0 },
      { name: 'Adjust future forecast', id: 1 },
    ];
  }

  static getVaribleByName(name: string, variables: any[]) {
    let variable = variables.filter(varible => {
      return varible.name === name;
    });
    if (variable.length > 0) {
      return variable.length > 0 ? variable[0].current.value : -1;
    }
  }

  static getVariableTextByValue(variableName: string, value: string, variables: any[]) {
    let variable = variables.filter(variable => {
      return variable.name === variableName;
    });
    if (variable.length > 0) {
      const item = variable[0].options.filter((option: any) => {
        return option.value === value;
      });
      return item.length > 0 ? item[0].text : null;
    }
  }

  static getIdFromSiteName(text: string) {
    const re = /\((.*)\)/i;
    let res = text.match(re);
    if (res && res[1] === 'allebutikker') {
      res = null;
    }
    return res ? res[1] : null;
  }

  static getIdFromItemName(text: string) {
    const re = /\|.(.*)/i;
    const res = text.match(re);
    return res ? res[1] : null;
  }

  static canExport(siteText: string, itemText: string) {
    const siteClientId = this.getIdFromSiteName(siteText);
    return siteClientId && siteClientId.length >= 4;
  }

  static findTag(tagName: string, tags: any[]): { key: string; value: number } {
    let retVal = null;
    if (!tags) {
      return retVal;
    }
    const selectorTag = tags.find(tag => {
      return tag.startsWith(tagName);
    });
    if (selectorTag) {
      const val = selectorTag.split(':')[1];
      return { key: tagName, value: parseInt(val, 10) };
    }
    return retVal;
  }

  static findTagAsString(tagName: string, tags: any[]): { key: string; value: string } {
    let retVal = null;
    if (!tags) {
      return retVal;
    }
    const selectorTag = tags.find(tag => {
      return tag.startsWith(tagName);
    });
    if (selectorTag) {
      const val = selectorTag.split(':')[1];
      return { key: tagName, value: val };
    }
    return retVal;
  }

  static findTagAsBoolean(tagName: string, tags: any[]): { key: string; value: boolean } {
    let retVal = null;
    if (!tags) {
      return retVal;
    }
    const selectorTag = tags.find(tag => {
      return tag.startsWith(tagName);
    });
    if (selectorTag) {
      const val: string = selectorTag.split(':')[1];
      return { key: tagName, value: val.toLowerCase() === 'true' };
    }
    return retVal;
  }

  static formatFromDate(date: DateTimeInput, timezone: string): string {
    const format = 'YYYY-MM-DD';

    date = isDateTime(date) ? date : dateTime(date);
    date.add(1, 'day');
    date.startOf('day');
    if (timezone === 'utc') {
      date.add(date.utcOffset(), 'minutes');
      return toUtc(date).format(format);
    } else {
      return dateTime(date).format(format);
    }
  }

  static formatToDate(date: DateTimeInput, timezone: string): string {
    const format = 'YYYY-MM-DD';

    date = isDateTime(date) ? date : dateTime(date);
    date.startOf('day');
    if (timezone === 'utc') {
      date.add(date.utcOffset(), 'minutes');
      return toUtc(date).format(format);
    } else {
      return dateTime(date).format(format);
    }
  }
}

import React, { FC } from 'react';
import _ from 'lodash';
import TopSectionItem from './TopSectionItem';
import config from '../../config';
import { contextSrv } from 'app/core/services/context_srv';

const TopSection: FC<any> = () => {
  const navTree = _.cloneDeep(config.bootData.navTree);
  const mainLinks = _.filter(navTree, item => !item.hideFromMenu);

  return (
    <div className="sidemenu__top">
      {mainLinks.map((link, index) => {
        if (link.text !== 'Dashboards' && contextSrv.isEditor) {
          return <TopSectionItem link={link} key={`${link.id}-${index}`} />;
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default TopSection;
